<template>
    <div class="settings">
        <NavTab title="设置"></NavTab>
        <div class="settings-item">
            <div class="settings-title">账号</div>
            <van-cell-group :border="false">
                <van-cell title="个人资料" is-link @click="$router.push('/userInfo')" />
                <van-cell title="修改登录密码" is-link @click="$router.push('/editPassword')" />
            </van-cell-group>
        </div>
        <div class="logout-btn">
            <van-button plain size="large" type="primary" @click="handleLogout">退出登录</van-button>
        </div>
    </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
export default {
    name: 'settings',
    components:{
        NavTab
    },
    data() {
        return {

        };
    },
    computed:{
    },
    mounted() {

    },

    methods: {
        // 退出登录
        handleLogout()
        {
           this.$dialog.confirm({
           message: '退出登录?',
            })
         .then(() => {
          // on confirm
          this.$store.commit('setToken','');
          localStorage.setItem('token','');
            this.$store.commit('setUserInfo',{});
            this.$router.push('/login');
       })
          .catch(() => {
    // on cancel
           });
            
            
        }
    },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>